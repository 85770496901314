import React, { useState } from "react";
import Document from "../../components/svgs/Document/Document";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { wrap, link, svg } from "./Document.module.scss";
import AnchorSvg from "../../components/svgs/Anchor/Anchor";
import useLang from "../../contexts/Language/LanguageContext";
import unConvention from "../../files/Zakon o ratifikaciji Konvencije Ujedinjenih Nacija o pravima lica sa invaliditetom sa Opcionim protokolom.pdf";
import discLaw from "../../files/Zakon o zabrani diskriminacije.pdf";
import osiDisc from "../../files/Zakon o zabrani diskriminacije lica sa invaliditetom.pdf";
import lawChildProtect from "../../files/Zakon_soc_zastita.pdf";
import medIndic from "../../files/Pravilnik o medicinskim indikacijama za ostvarivanje prava na materijalno obezbjedjenje dodatka za njegu i pomoc licnu invalidninu i naknadu zarade za rad sa polovinom punog radnog vremena.pdf";
import commSupport from "../../files/Pravilnik o blizim uslovima za pruzanje i koriscenje normativima i minimalnim standardima usluga podrske za zivot u zajednici.pdf";
import parentPay from "../../files/Pravilnik o kriterijumima i mjerilima za ucesce korisnika roditelja odnosno srodnika u placanju troskova usluga podrske za zivot u zajednici savjetodavno-terapijskih i socijalno-edukativnih usluga i u.pdf";
import socWork from "../../files/Pravilnik o organizaciji normativima standardima i nacinu rada centra za socijalni rad.pdf";
import profRehab from "../../files/Zakon o profesionalnoj rehabilitaciji i zaposljavanju lica sa invaliditetom.pdf";
import percInval from "../../files/Pravilnik o kriterijumima i uslovima za utvrdjivanje procenta invaliditeta preostale radne sposobnosti i mogucnosti zaposlenja.pdf";
import profRehabCond from "../../files/Pravilnik o nacinu i uslovima ostvarivanja prava na profesionalnu rehabilitaciju.pdf";
import profRehabActiv from "../../files/Pravilnik o standardima za sprovodjenje mjera i aktivnosti profesionalne rehabilitacije.pdf";
import subvReqCond from "../../files/Pravilnik o uslovima kriterijumima i postupku ostvarivanja prava na subvencije.pdf";
import useIsMobile from "../../utils/is-mobile/isMobile";

const Docs = [
  {
    mne: "Zakon o ratifikaciji Konvencije Ujedinjenih Nacija o pravima lica sa invaliditetom sa Opcionim protokolom",
    en: "Law on Ratification of the United Nations Convention on the Rights of Persons with Disabilities with the Optional Protocol",
    doc: unConvention,
  },
  {
    mne: "Zakon o zabrani diskriminacije",
    en: "Law on Prohibition of Discrimination",
    doc: discLaw,
  },
  {
    mne: "Zakon o zabrani diskriminacije lica sa invaliditetom",
    en: "Law on Prohibition of Discrimination against Persons with Disabilities",
    doc: osiDisc,
  },
  {
    mne: "Zakon o socijalnoj i dječjoj zaštiti",
    en: "Law on Social and Child Protection",
    doc: lawChildProtect,
  },
  {
    mne: "Pravilnik o medicinskim indikacijama za ostvarivanje prava na materijalno obezbjeđenje dodatka za njegu i pomoć ličnu invalidninu i naknadu zarade za rad sa polovinom punog radnog vremena",
    en: "Ordinance on medical indications for obtaining the right to material security of allowance for care and assistance, personal disability allowance and compensation of salary for part-time work",
    doc: medIndic,
  },
  {
    mne: "Pravilnik o bližim uslovima za pružanje i korišćenje normativima i minimalnim standardima usluga podrške za život u zajednici",
    en: "Ordinance on detailed conditions for the provision and use of norms and minimum standards of support services for community life",
    doc: commSupport,
  },
  {
    mne: "Pravilnik o kriterijumima i mjerilima za učešće korisnika roditelja odnosno srodnika u plaćanju troškova usluga podrške za život u zajednici savjetodavno-terapijskih i socijalno-edukativnih usluga i usluga smještaja",
    en: "Ordinance on criteria and measures for the participation of beneficiaries of parents or relatives in the payment of costs of support services for community living, counseling-therapeutic and social-educational services and accommodation services",
    doc: parentPay,
  },
  {
    mne: "Pravilnik o organizaciji, normativima, standardima i načinu rada centra za socijalni rad",
    en: "Rulebook on the organization of norms, standards and the manner of work of the center for social work",
    doc: socWork,
  },
  {
    mne: "Zakon o profesionalnoj rehabilitaciji i zapošljavanju lica sa invaliditetom",
    en: "Law on Professional Rehabilitation and Employment of Persons with Disabilities",
    doc: profRehab,
  },
  {
    mne: "Pravilnik o kriterijumima i uslovima za utvrđivanje procenta invaliditeta preostale radne sposobnosti i mogućnosti zaposlenja",
    en: "Rulebook on criteria and conditions for determining the percentage of disability, remaining working ability and employment opportunities",
    doc: percInval,
  },
  {
    mne: "Pravilnik o načinu i uslovima ostvarivanja prava na profesionalnu rehabilitaciju",
    en: "Rulebook on the manner and conditions of exercising the right to professional rehabilitation",
    doc: profRehabCond,
  },
  {
    mne: "Pravilnik o standardima za sprovodjenje mjera i aktivnosti profesionalne rehabilitacije",
    en: "Rulebook on standards for the implementation of measures and activities of professional rehabilitation",
    doc: profRehabActiv,
  },
  {
    mne: "Pravilnik o uslovima kriterijumima i postupku ostvarivanja prava na subvencije",
    en: "Rulebook on conditions, criteria and procedure for exercising the right to subsidies",
    doc: subvReqCond,
  },
];

const Links = [
  {
    mne: "Vlada Crne Gore",
    en: "Government of Montenegro",
    link: "https://www.gov.me/",
    linken: "https://www.gov.me/en/",
  },
  {
    mne: "Portal e-uprave Crne Gore",
    en: "E-Government portal of Montenegro",
    link: "https://www.euprava.me/",
    linken: "https://www.euprava.me/en",
  },
  {
    mne: "Zajednica opština Crne Gore",
    en: "Union of municipalities of Montenegro",
    link: "http://uom.me/",
  },
  {
    mne: "JU Centri za socijalni rad",
    en: "Centers for social work",
    link: "https://www.csrcg.me/",
  },
  {
    mne: "Zavod za zapošljavanje Crne Gore",
    en: "Employment agency of Montenegro",
    link: "https://www.zzzcg.me/",
  },
  {
    mne: "Fond za zdravstveno osiguranje Crne Gore",
    en: "Health Insurance Fund of Montenegro",
    link: "https://fzocg.me/",
  },
  {
    mne: "Fond penzijskog i invalidskog osiguranja Crne Gore",
    en: "Pension and Disability Insurance Fund of Montenegro",
    link: "https://www.fondpio.me/",
  },
  {
    mne: "Zaštitnik ljudskih prava i sloboda",
    en: "Ombudsman",
    link: "https://www.ombudsman.co.me/",
  },
  {
    mne: "Sudovi Crne Gore",
    en: "Courts of Montenegro",
    link: "https://sudovi.me/",
  },
  {
    mne: "Drzavno tuzilaštvo Crne Gore",
    en: "State Prosecutions Of Montenegro",
    link: "https://tuzilastvo.me/",
  },
  {
    mne: "Kancelarija visokog Komesara UN-a za ljudska prava",
    en: "UN Human Rights Office Of the High Commissioner",
    link: "https://www.ohchr.org/en/hrbodies/crpd/pages/crpdindex.aspx",
  },
];

export default function Materials() {
  const lang = useLang();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useIsMobile();
  return (
    <>
      <Tabs
        orientation={isMobile ? "vertical" : "horizontal"}
        value={value}
        onChange={handleChange}
        aria-label="odaberite link"
        variant="scrollable"
        scrollButtons="on"
      >
        <Tab
          id="docs-tab"
          aria-controls="materials-docs"
          style={{ color: "white" }}
          label={
            lang === "mne"
              ? "Prateće zakonodavstvo"
              : "Accompanying legislation"
          }
        />
        <Tab
          id="links-tab"
          aria-controls="materials-links"
          style={{ color: "white" }}
          label={lang === "mne" ? "Korisni linkovi" : "Useful links"}
        />
      </Tabs>
      {value === 0 ? (
        <div
          id="materials-docs"
          aria-labelledby="docs-tab"
          role="tabpanel"
          style={{ marginTop: "20px" }}
        >
          {Docs.map((d) => (
            <div key={d.mne} className={wrap}>
              <div className={svg}>
                <Document />
              </div>
              <a className={link} href={d.doc} download>
                {lang === "mne" ? d.mne : d.en}
              </a>
            </div>
          ))}
        </div>
      ) : (
        <div
          id="materials-links"
          aria-labelledby="links-tab"
          role="tabpanel"
          style={{ marginTop: "20px" }}
        >
          {Links.map((l) => (
            <div key={l.mne} className={wrap}>
              <div className={svg}>
                <AnchorSvg />
              </div>
              <a
                className={link}
                target="_blank"
                rel="noreferrer"
                href={lang === "mne" ? l.link : l.linken ? l.linken : l.link}
              >
                {lang === "mne" ? l.mne : l.en}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
